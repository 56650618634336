import React, { useEffect, useState } from "react";

import LogoutIcon from '@mui/icons-material/Logout';


import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import type { Post } from "./PostDetail";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface Label {
  name: string;
  count: number;
}


function Sidebar() {
  const [labels, setLabels] = useState<Label[] | null>(null);
  const [showPages, setShowPages] = useState<boolean>(false);
  const { t } = useTranslation();
  const login= (localStorage.getItem("token")) ;
  useEffect(() => {
    (async () => {
     
    })();
  }, []);
  const navigate = useNavigate();
  return (
    <Stack>
      <List>
        <ListItemButton component={RouterLink} to="/">
          <ListItemText primary={t("Home")} />
        </ListItemButton>
          <ListItemButton component={RouterLink} to="/admin">
          <ListItemText primary={ t("Posts")+"-"+t("Admin Panel")} />
        </ListItemButton>

        {login&&<ListItemButton 
         onClick={()=>{
         localStorage.removeItem("token");
         document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

         navigate("/");
         }}       >
          <LogoutIcon/>

        </ListItemButton>} 

      </List>
      <Divider />
     
    </Stack>
  );
}

export default Sidebar;
