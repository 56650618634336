import React from "react";

import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { ArrowBack, Send, MoreVert as MoreVertIcon } from "@mui/icons-material";
import {
  Outlet,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

function Layout() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (localStorage.getItem("token")) return;
    navigate("/login");
  }, [navigate]);

  return (
    <div className="App">
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() =>  navigate("/")}
          >
            <ArrowBack/>
          </IconButton>
       
           
          
           {t("Admin Panel")+":"+t("Posts")}
          
        </Toolbar>
      </AppBar>

      <Outlet />
    </div>
  );
}

export default Layout;
